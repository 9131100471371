import { createGlobalStyle } from "styled-components";
import colors from "./colors";

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1025px",
  laptopL: "1441px",
  desktop: "2561px"
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

const GlobalStyles = createGlobalStyle`

    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
  
  
    *::-webkit-scrollbar {
        display: none;
    }
  
    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }


    html, body {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      min-height: 100%;
      -webkit-font-smoothing: antialiased;
      outline: none;
      background: #F4F6FA;
    }

    h1, h2, h3, h4{
      color: #3E3E3E;
      font-family: 'Montserrat', sans-serif;
    }

    .error-message {
      color: red;
      font-size: 14px
    }

    .active-menu {
      color:  #3E3E3E !important;
    }

    .loader {
      border: 3px solid #f3f3f3;
      border-top: 3px solid ${colors.pri};
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .primary-btn {
      background: ${colors.pri};
      color: #000 !important;
    }

    .dark-btn {
      color: #fff;
      background: #000;
    }
    
    .primary-outline-btn {
      border: 2px solid ${colors.pri} !important;
      color: ${colors.pri} !important;
      background: #fff
    }

    /* core styles for table */
  .table{
    width: 100%;
    font-size: 0.95rem;
    border-spacing: 0;
    border-collapse: collapse;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24);
    background-color: var(--tableBgColor, #fff);
  }

  .table__thead{
    background-color: var(--tableTitleBgColor);
    color: var(--tableTitleTextColor);
  }

  .table__mobile-caption{
    display: none;
  }

  .table__td, .table__th{
    padding: var(--tableCellPadding, 20px);
    box-sizing: border-box;
  }

  @media screen and (min-width: 769px){

    .table__tr:hover{
      background-color: var(--tableRowBgColorHover);
    }

    .table__td:first-child, .table__th:first-child{
      // width: 30%;
      text-align: left;
    }

    .table__td, .table__th{
      text-align: center;
    }
  }

  @media screen and (max-width: 768px){

    .table, .table__thead, .table__tbody, .table__tr{
      display: block;
    }

    .table__td{
      display: flex;
    }

    .table__head{
      display: none;
    }

    .table__mobile-title{
      background-color: var(--tableTitleBgColor);
      color: var(--tableTitleTextColor);
    }

    .table__td{
      border-bottom: 1px solid var(--tableSeparatorColor);
    }

    .table__td:last-of-type{
      border-bottom: none;
    }

    .table__mobile-caption, .table__value{
      display: block;
      width: 50%;
      box-sizing: border-box;
    }

    .table__mobile-caption{
      padding-right: 2%;
    }
  }

  /* demo styles for table */

  .table{
    --tableCellPadding: 2rem;
    --tableTitleBgColor: #fef5ba;
    --tableTitleTextColor: #fff;
    --tableRowBgColorHover: #f0f0f0;
    --tableSeparatorColor: #eee;
  }
`;

export default GlobalStyles;
