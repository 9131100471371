import React from "react";
import { loginKey } from "utils/constants";
import storage from "utils/storage";
const loadAuthenticatedApp = () => import("../app/authenticated");
const UnauthenticatedApp = React.lazy(() => import("../app/unauthenticated"));
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);

const Layout = () => {
  const token = storage.get(loginKey);
  return (
    <>
      <div>{token ? <AuthenticatedApp /> : <UnauthenticatedApp />}</div>
    </>
  );
};

export default Layout;
