import {
  ChampionContractInfo,
  ChampionTrancheInfo,
  ChampionAmortScheduleResponse,
  ChampionInfo,
  ChampionAmortContractInfo,
  ChampionPausedDaysInfo
} from "interfaces/championOverview";
import React from "react";

const show = false;
const activeRow = -1;
const totalPausedDays = 0;
const status = "";
const champion_id = "";
const contract_id = "";

const initState = {
  championInfo: {} as ChampionContractInfo,
  championBioInfo: {} as ChampionInfo,
  championContractInfo: [] as ChampionAmortContractInfo[],
  championTrancheInfo: [] as ChampionTrancheInfo[],
  championAmortScheduleInfo: {} as ChampionAmortScheduleResponse,
  showAmortProfile: show,
  showAmortSchedule: show,
  showChampionOverview: { show: show, index: activeRow },
  activeTableRow: activeRow,
  championPausedDaysInfo: [] as ChampionPausedDaysInfo[],
  championTotalPausedDays: totalPausedDays,
  showPausedDaysInfoModal: show,
  showRepaymentSchedule: show,
  championTrancheInfoStatus: status,
  championAmortizationInfoStatus: status,
  championId: champion_id,
  championContractId: contract_id,
  // eslint-disable-next-line
  storeChampionInfo: (value: ChampionContractInfo) => {},
  // eslint-disable-next-line
  storeChampionBioInfo: (value: ChampionInfo) => {},
  // eslint-disable-next-line
  storeChampionContractInfo: (value: ChampionAmortContractInfo[]) => {},
  // eslint-disable-next-line
  storeChampionTrancheInfo: (value: ChampionTrancheInfo[]) => {},
  // eslint-disable-next-line
  storeChampionAmortScheduleInfo: (value: ChampionAmortScheduleResponse) => {},
  // eslint-disable-next-line
  storeShowAmortProfile: (value: boolean) => {},
  // eslint-disable-next-line
  storeShowAmortSchedule: (value: boolean) => {},
  // eslint-disable-next-line
  storeShowChampionOverview: (value: boolean, index: number) => {},
  // eslint-disable-next-line
  storeActiveTableRow: (value: number) => {},
  // eslint-disable-next-line
  storeChampionPausedDaysInfo: (value: ChampionPausedDaysInfo[]) => {},
  // eslint-disable-next-line
  storeChampionTotalPausedDays: (value: number) => {},
  // eslint-disable-next-line
  storeShowPausedDaysInfoModal: (value: boolean) => {},
  //eslint-disable-next-line
  storeShowRepaymentSchedule: (value: boolean) => {},
  //eslint-disable-next-line
  storeChampionTrancheInfoStatus: (value: string) => {},
  //eslint-disable-next-line
  storeChampionAmortizationInfoStatus: (value: string) => {},
  //eslint-disable-next-line
  storeChampionId: (value: string) => {},
  //eslint-disable-next-line
  storeChampionContractId: (value: string) => {}
};

const ChampionContext = React.createContext(initState);
export const ChampionProvider = ChampionContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

export const ChampionProviderContainer: React.FC<Props> = ({ children }) => {
  const [championInfo, setChampionInfo] = React.useState<ChampionContractInfo>(
    initState.championInfo
  );

  const [championTrancheInfo, setChampionTrancheInfo] = React.useState<
    ChampionTrancheInfo[]
  >(initState.championTrancheInfo);

  const [championAmortScheduleInfo, setChampionAmortScheduleInfo] =
    React.useState<ChampionAmortScheduleResponse>(
      initState.championAmortScheduleInfo
    );

  const [championContractInfo, setChampionContractInfo] = React.useState<
    ChampionAmortContractInfo[]
  >(initState.championContractInfo);

  const [championBioInfo, setChampionBioInfo] = React.useState<ChampionInfo>(
    initState.championBioInfo
  );

  const storeChampionInfo = (info: ChampionContractInfo) => {
    setChampionInfo(info);
  };

  const storeChampionTrancheInfo = (info: ChampionTrancheInfo[]) => {
    setChampionTrancheInfo(info);
  };

  const storeChampionAmortScheduleInfo = (
    info: ChampionAmortScheduleResponse
  ) => {
    setChampionAmortScheduleInfo(info);
  };

  const storeChampionContractInfo = (info: ChampionAmortContractInfo[]) => {
    setChampionContractInfo(info);
  };

  const storeChampionBioInfo = (info: ChampionInfo) => {
    setChampionBioInfo(info);
  };

  const [showAmortProfile, setShowAmortProfile] = React.useState(
    initState.showAmortProfile
  );
  const storeShowAmortProfile = (info: boolean) => {
    setShowAmortProfile(info);
  };

  const [showAmortSchedule, setShowAmortSchedule] = React.useState(
    initState.showAmortSchedule
  );
  const storeShowAmortSchedule = (info: boolean) => {
    setShowAmortSchedule(info);
  };

  const [showChampionOverview, setShowChampionOverview] = React.useState(
    initState.showChampionOverview
  );
  const storeShowChampionOverview = (value: boolean, index: number) => {
    setShowChampionOverview({ show: value, index: index });
  };

  const [activeTableRow, setActiveTableRow] = React.useState(
    initState.activeTableRow
  );
  const storeActiveTableRow = (info: number) => {
    setActiveTableRow(info);
  };

  const [championPausedDaysInfo, setChampionPausedDaysInfo] = React.useState<
    ChampionPausedDaysInfo[]
  >(initState.championPausedDaysInfo);
  const storeChampionPausedDaysInfo = (info: ChampionPausedDaysInfo[]) => {
    setChampionPausedDaysInfo(info);
  };

  const [championTotalPausedDays, setChampionTotalPausedDays] = React.useState(
    initState.championTotalPausedDays
  );
  const storeChampionTotalPausedDays = (info: number) => {
    setChampionTotalPausedDays(info);
  };

  const [showPausedDaysInfoModal, setShowPausedDaysInfoModal] = React.useState(
    initState.showPausedDaysInfoModal
  );
  const storeShowPausedDaysInfoModal = (info: boolean) => {
    setShowPausedDaysInfoModal(info);
  };

  const [showRepaymentSchedule, setShowRepaymentSchedule] = React.useState(
    initState.showRepaymentSchedule
  );
  const storeShowRepaymentSchedule = (info: boolean) => {
    setShowRepaymentSchedule(info);
  };

  const [championTrancheInfoStatus, setChampionTrancheInfoStatus] =
    React.useState(initState.championTrancheInfoStatus);
  const storeChampionTrancheInfoStatus = (info: string) => {
    setChampionTrancheInfoStatus(info);
  };

  const [championAmortizationInfoStatus, setChampionAmortizationInfoStatus] =
    React.useState(initState.championAmortizationInfoStatus);
  const storeChampionAmortizationInfoStatus = (info: string) => {
    setChampionAmortizationInfoStatus(info);
  };

  const [championId, setChampionId] = React.useState(initState.championId);
  const storeChampionId = (info: string) => {
    setChampionId(info);
  };

  const [championContractId, setChampionContractId] = React.useState(
    initState.championId
  );
  const storeChampionContractId = (info: string) => {
    setChampionContractId(info);
  };

  return (
    <ChampionProvider
      value={{
        championInfo,
        storeChampionInfo,
        championTrancheInfo,
        storeChampionTrancheInfo,
        championAmortScheduleInfo,
        storeChampionAmortScheduleInfo,
        championContractInfo,
        storeChampionContractInfo,
        championBioInfo,
        storeChampionBioInfo,
        showAmortProfile,
        storeShowAmortProfile,
        showAmortSchedule,
        storeShowAmortSchedule,
        showChampionOverview,
        storeShowChampionOverview,
        activeTableRow,
        storeActiveTableRow,
        championPausedDaysInfo,
        storeChampionPausedDaysInfo,
        championTotalPausedDays,
        storeChampionTotalPausedDays,
        showPausedDaysInfoModal,
        storeShowPausedDaysInfoModal,
        showRepaymentSchedule,
        storeShowRepaymentSchedule,
        championTrancheInfoStatus,
        storeChampionTrancheInfoStatus,
        championAmortizationInfoStatus,
        storeChampionAmortizationInfoStatus,
        championId,
        storeChampionId,
        championContractId,
        storeChampionContractId
      }}
    >
      {children}
    </ChampionProvider>
  );
};

export default ChampionContext;
