import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import GlobalStyles from "styles";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "fonts.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChampionProviderContainer } from "context/championContext";
import {CollectionProviderContainer} from "context/collection";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <Toaster position="top-center" reverseOrder={false} gutter={8} />
      <ChampionProviderContainer>
        <CollectionProviderContainer>
          <App />
        </CollectionProviderContainer>
      </ChampionProviderContainer>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
