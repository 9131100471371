import Layout from "layout/layout";
import React from "react";

function App() {
  return (
    <React.Suspense fallback={"App loading"}>
      <Layout />
    </React.Suspense>
  );
}

export default App;
